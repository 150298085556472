<template>
  <v-container v-if='LOGGED_IN' class="py-0">
    <v-row justify='center'><v-col cols=12 xl=10>

    <v-card class="primary mb-4" dark>

      <v-card-text class="">
        <v-row class="">

          <!-- REPORT SELECTION -->
          <v-col cols="12" md="6" lg="4" class="">
            <v-select
              v-model="page"
              :items="reports"
              item-text="label" item-value="key"
              placeholder="Select Report"
              hide-details outlined dense class=""
              @change="changePage"
            />
          </v-col>

          <!-- FROM DATE -->
          <v-col cols="12" md="3" lg="3" xl="2">
            <v-menu v-model="date_from_popup" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense hide-details :value="date_from_formatted" v-on="on" v-bind="attrs" readonly label="From" clearable @click:clear="date_from=null" />
              </template>
              <v-date-picker v-model="date_from" no-title />
            </v-menu>
          </v-col>

          <!-- TO DATE -->
          <v-col cols="12" md="3" lg="3" xl="2">
            <v-menu v-model="date_to_popup" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense hide-details :value="date_to_formatted" v-on="on" v-bind="attrs" readonly label="To" clearable @click:clear="date_to=null" />
              </template>
              <v-date-picker v-model="date_to" no-title />
            </v-menu>
          </v-col>

        </v-row>
        
      </v-card-text>

    </v-card>
  
    
    <router-view :date_from="date_from" :date_to="date_to" />
    </v-col></v-row>
  </v-container>
</template>

<style lang='scss' >
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Invoices',

  components: {
  },

  data: () => ({
    reports: [
      //{ label: "Please Select...", key: '' },
      { label: "QMS Completion", key: 'qms_completion' },
    ],
    page: null,

    date_from: null,
    date_from_popup: false,

    date_to: null,
    date_to_popup: false,

  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN']),

    date_from_formatted(){ return this.formatDate(this.date_from); },
    date_to_formatted(){ return this.formatDate(this.date_to); },

  },
  watch: {
    '$route.path'(){ this.pathChanged(); },
  },
  methods:{
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('UI', ['SHOW_NAVBARS', 'SET_PAGE_TITLE', 'NAVIGATE']),
    //...mapActions('API', ['API']),

    changePage(){ this.NAVIGATE(`/reports/${this.page}`); },

    pathChanged(){
      this.page = this.$route.fullPath.split('/')[2] ?? "";
      if(this.page == "") this.SET_PAGE_TITLE("Reports");
    },

    formatDate(date){ return date ? moment(date).format("DD/MM/YY") : null; },

  },
  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS();
      this.pathChanged();
    });
  }
};
</script>
