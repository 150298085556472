var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('v-card', {
    staticClass: "primary mb-4",
    attrs: {
      "dark": ""
    }
  }, [_c('v-card-text', {}, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.reports,
      "item-text": "label",
      "item-value": "key",
      "placeholder": "Select Report",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.changePage
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "2"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "max-width": "290px",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "value": _vm.date_from_formatted,
            "readonly": "",
            "label": "From",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.date_from = null;
            }
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2142028131),
    model: {
      value: _vm.date_from_popup,
      callback: function callback($$v) {
        _vm.date_from_popup = $$v;
      },
      expression: "date_from_popup"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": ""
    },
    model: {
      value: _vm.date_from,
      callback: function callback($$v) {
        _vm.date_from = $$v;
      },
      expression: "date_from"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "2"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "max-width": "290px",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "value": _vm.date_to_formatted,
            "readonly": "",
            "label": "To",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.date_to = null;
            }
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 4245301102),
    model: {
      value: _vm.date_to_popup,
      callback: function callback($$v) {
        _vm.date_to_popup = $$v;
      },
      expression: "date_to_popup"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": ""
    },
    model: {
      value: _vm.date_to,
      callback: function callback($$v) {
        _vm.date_to = $$v;
      },
      expression: "date_to"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('router-view', {
    attrs: {
      "date_from": _vm.date_from,
      "date_to": _vm.date_to
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }